import BaseBean from "@/utils/BaseBean";
import CorpList from '@/views/sysviews/organization/corp/CorpList.vue';
export interface IMapPointDataObj {
    utilInst:any
    carPng: any
    coordinate:Array<any>
    markerMap:Map<any,any>
    markerInfo:any
    searchContent:string
    mapInst:any
    AMap:any
    geocoder:any
    searchAutoComplete:any
}
export default class MapPointUtil extends BaseBean{
    public dataObj:IMapPointDataObj

    constructor(proxy:any,dataObj:IMapPointDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }

    //初始化
    public async initMap(options:any):Promise<void>{
        let mapResult=await this.proxy.utils.map.buildMapInst({markerPoints:[],container:'mapPoint'});
        this.dataObj.AMap=mapResult.AMap;
        this.dataObj.mapInst=mapResult.mapInst;
        this.dataObj.searchAutoComplete=mapResult.searchAutoComplete;
        this.dataObj.geocoder=mapResult.geocoder;

        this.dataObj.markerInfo=options.markerInfo;
        this.dataObj.mapInst.setZoom(5);
        this.buildMarkerByMapCarInfo(options.markerInfo);
    }
    //在地图是创建marker
    public buildMarkerByMapCarInfo(iterms:any):void{
        iterms.forEach((item:any)=>{
            this.buildMarker(item)
        })
    }
    //创建marker
    public buildMarker(options:any):void{
        let marker = new this.dataObj.AMap.Marker({
            extData:options,
            icon: this.dataObj.carPng,
            position:[options.lng,options.lat],
            title: options.title?options.title:''
        });
        this.dataObj.markerMap.set(marker._opts.extData.carId,marker);
        let that=this as any;
        marker.on('click', function(e:any){
            if(e.originEvent.target.localName!='img')return;
            let temMapCarInfo=[].concat(that.dataObj.markerInfo) as any;
            for(let i=0;i<temMapCarInfo.length;i++){
                if(temMapCarInfo[i].carId==marker._opts.extData.carId){
                    temMapCarInfo.splice(i, 1);break;
                }
            }
            for(let [key,mapMaker] of that.dataObj.markerMap){
                if(marker._opts.extData.carId!=key){
                    mapMaker.setMap();
                }
            }
            that.buildMarkerByMapCarInfo(temMapCarInfo);


            if(!marker.getExtData().visible){
                marker.getExtData().visible=true;
                let content=`<div style="width: 180px;height: 200px;padding: 20px;">
                                   <div style="display: flex;padding-top: 10px;">
                                        <span style="margin-right:10px;">订单数</span>
                                        <span style="cursor: pointer;" onclick="markerLabelClick('${marker._opts.extData.carId}')">${marker._opts.extData.orderNum}</span>
                                   </div>
                                   <div style="display: flex;padding-top: 10px;">
                                        <span style="margin-right: 10px;">订单状态</span>
                                        <span>运输中</span>
                                   </div>
                                    <div style="display: flex;padding-top: 10px;">
                                         <span style="margin-right: 10px">车型</span>
                                         <span>${marker._opts.extData.cx}</span>
                                   </div>
                                   <div style="display: flex;padding-top: 10px;">
                                         <span style="margin-right: 10px">车牌号</span>
                                         <span>${marker._opts.extData.carNum}</span>
                                   </div>
                                   <div style="display: flex;padding-top: 10px;">
                                         <span style="margin-right: 10px">司机姓名</span>
                                         <span>${marker._opts.extData.driverName}</span>
                                   </div>
                                   <div style="display: flex;padding-top: 10px;">
                                         <span style="margin-right: 10px">司机电话</span>
                                         <span>${marker._opts.extData.driverPhone}</span>
                                   </div>
                                   <div style="display: flex;padding-top: 10px;">
                                          <span style="margin-right: 10px">车辆定位</span>
                                          <span>${marker._opts.extData.carAddress.length>10?marker._opts.extData.carAddress.substr(0,10)+'...':marker._opts.extData.carAddress}</span>
                                   </div>
                                   <div style="display: flex;padding-top: 10px;">
                                         <span style="margin-right: 10px">速度</span>
                                         <span>${marker._opts.extData.carSpeed}</span>
                                   </div>
                                </div>`;
                if(!marker.getExtData().isTop)  {//把当前正在的操作的marker置顶，奇怪，如果置顶过一次之后，再去调用setTop方法，不管传入false还是true，都会搞成false，所以必须重绘其它marker
                    marker.setTop(true);
                    marker.getExtData().isTop=true;
                }
                marker.setLabel({
                    direction:'right',
                    offset: new that.dataObj.AMap.Pixel(10, 0),//设置文本标注偏移量
                    content: content//设置文本标注内容
                });
            }else{
                marker.setLabel({
                    content:null
                });
                marker.getExtData().visible=false;
            }
        });
        this.dataObj.mapInst.add(marker);
    }
    //marker中label里面的content点击事件（点击marker可以打开marker身上的label，label里面有很多信息，可以点击一些连接等等）
    public async markerLabelClick(params1:any):Promise<void>{
        let opts={
            modelComp: CorpList,
            proxy: this.proxy,
            title:"点击地图marker中label",
            type:'carAddress',
            params1:params1,
            fullscreen:true,
            showFooterBtn:false
        }
        await this.utils.UtilPub.openDialog(opts);
    }
    //搜索
    public doSearch():void{
        if(this.dataObj.searchContent){
            const loading = this.proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            this.dataObj.searchAutoComplete.search(this.dataObj.searchContent, (status:any, result:any)=>{
                let newCenter=[];let flag=true;let point0Name='';
                let map=new Map();
                if(result && result.tips){
                    let tips=result.tips;
                    let len=tips.length;
                    if(len>5)len=5;
                    for(let i=0;i<len;i++){
                        if(flag && tips[i] && tips[i]['location']){
                            newCenter[0]=tips[i]['location']['lng'];
                            newCenter[1]=tips[i]['location']['lat'];
                            point0Name=tips[i].name;
                            flag=false;
                        }
                        map.set(tips[i]['location']['lng'],tips[i]['location']['lat']);
                    }
                }
                if(newCenter.length>0){
                    this.proxy.utils.map.buildMarker({lng:newCenter[0],lat:newCenter[1],title:point0Name,AMap:this.dataObj.AMap,mapInst:this.dataObj.mapInst});
                    this.dataObj.mapInst.setCenter(newCenter,true,500);
                    this.dataObj.coordinate=[newCenter[0],newCenter[1]];
                }
                loading.close();
            })
        }
    }
}