import CorpCard from './CorpCard.vue';
import {reactive, toRefs, onBeforeMount, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
import {TableColumnCtx} from "element-plus/es/components/table/src/table-column/defaults";
import CorpListUtil,{ICorpListDataObj} from "@/views/sysviews/organization/corp/corpListUtil";
import language from './corpLanguage'
export default defineComponent ({
    name: "corpList",
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:ICorpListDataObj=reactive<ICorpListDataObj>({
            utilInst:{} as any,
            value:'',
            options:[],
            refMap:new Map(),
            pageListRef:null,
            importGuidParams:{
                ownerInst:proxy,
                uploadParams:{
                    saveType:0,
                    action:utils.Api.buildUrl('/corp/importData')
                },
                downloadParams:{
                    sourceName:'corpImportTemplate.xlsx',
                    downLoadFileName:proxy.$t('corp.downLoadFileName'),//这个时候国际化语言包还没有准备好，定义了也没用，在onMounted那里有改变
                    action:utils.Api.buildUrl('/corp/downLoadResourceFile')
                }
            },
            pageList: {
                queryParam: {},
                modelComp: CorpCard,
                modelMethod: utils.Api.buildUrl("/corp/pageData")
            },
            otherParams:{
                value:'',
                disabled:false
            }
        })
        const formatShow=(row:any, column:TableColumnCtx<any>, value:any, index:number)=>{
            let num=row.F_LEVEL*2;
            let pre="";
            for(let i=0;i<num;i++)pre=pre+"\u3000"
            return pre+value;
        }
        onBeforeMount(()=>{
            dataObj.utilInst=new CorpListUtil(proxy,dataObj);
        })
        onMounted(async ()=>{
        })
        //导入按钮事件
        const importHandler=()=>{
            dataObj.refMap.get('importGuid').importDataDialogVisible=true
        }
        //---------------------------computed------------
        //下拉框初始化参数
        const comboGridParam=computed(()=>{
            return (params:any) => {
                if(params && 'corpName'==params.comboId){
                    return Object.assign({popoverAttrs:{}, gridAttrs:{}, inputAttrs:{}, showToolBar:false, multiple:true, modelMethod:"/corp/combo"},params)
                }
            };
        })
        //下拉树初始化参数
        const comboTreeParam=computed(()=>{
            return (params:any) => {
                if(params && 'corpName'==params.comboId){
                    return Object.assign({
                        showCheckbox:false,
                        isOnlySelectLeaf:true,
                        disabled:dataObj.otherParams.disabled,
                        modelMethod:"/corp"
                    },params)
                }
            };
        })
        return{
            ...toRefs(dataObj),formatShow,importHandler,comboGridParam,comboTreeParam

        }
    },
    components: {}
});